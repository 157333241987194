import { Slot } from "@radix-ui/react-slot";
import type { ComponentPropsWithoutRef, ElementRef, FC, ReactNode } from "react";
import { forwardRef } from "react";

import { IconCross } from "@/domains/icons";
import { cn } from "@/shared/styles";

const ActionButton = forwardRef<ElementRef<"button">, ComponentPropsWithoutRef<"button"> & { asChild?: boolean }>(
  ({ className, type = "button", asChild, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(
          "py-3 font-gilroy text-[14px] font-bold leading-[1.2] text-action md:py-4 md:text-[18px]",
          className,
        )}
        type={type}
        ref={ref}
        {...props}
      />
    );
  },
);

const CloseButton = forwardRef<ElementRef<"button">, ComponentPropsWithoutRef<"button">>(
  ({ className, type = "button", ...props }, ref) => (
    <button
      className={cn(
        "absolute end-4 top-4 grid size-6 place-items-center rounded-full bg-white/25 text-text-inverse backdrop-blur-[2px] md:end-6 md:top-6",
        className,
      )}
      type={type}
      ref={ref}
      {...props}
    >
      <IconCross />
    </button>
  ),
);

type Props = {
  text: string;
  actionButton: ReactNode;
  closeButton?: ReactNode;
  bonusImg?: string;
};

const Banner: FC<Props> = ({ text, actionButton, closeButton, bonusImg }) => {
  return (
    <div className="relative flex overflow-hidden overflow-y-hidden rounded-[24px]  bg-bg-additional">
      <div className="w-[70%] pb-2 pe-4 ps-6 pt-5 md:pe-6 md:ps-8 md:pt-6">
        <div className="font-gilroy text-[16px] font-semibold leading-[1.2] text-text-inverse md:text-[22px]">
          {text}
        </div>
        {actionButton}
      </div>
      {bonusImg && (
        <div className="relative grid grow place-items-center">
          <img
            src={bonusImg}
            className="absolute end-3 aspect-square h-[88px] w-[88px] object-contain md:bottom-[-20px] md:end-auto md:top-[-25px] md:h-[270px] md:w-[380px] md:-rotate-12 md:object-cover"
            alt=""
          />
        </div>
      )}
      {closeButton}
    </div>
  );
};

const Component = Object.assign(Banner, { ActionButton, CloseButton });

export { Component as Banner };
