import { createSearchParams, type To } from "react-router-dom";

export const cabinetRoutes = {
  dashboard: "/my-accounts",
  createAccount: "/my-accounts/create-account",
  deposit: "/deposit",
  depositOptions: "/deposit/:id",
  withdrawal: "/withdrawal",
  withdrawalOptions: "/withdrawal/:id",
  transfer: "/transfer",
  transactionHistory: "/transaction-history",
  trading: "/web-trading",
  mt4Terminal: "/web-trading/mt4",
  mt5Terminal: "/web-trading/mt5",
  notifications: "/notifications",
  profileSettings: "/profile",
};

const getDepositRoute = (accountId: string): To => `${cabinetRoutes.deposit}/${accountId}`;

const getWithdrawalRoute = (accountId: string, fromWithdrawal?: boolean): To => {
  return {
    pathname: `${cabinetRoutes.withdrawal}/${accountId}`,
    search: fromWithdrawal ? "fromWithdrawal" : void 0,
  };
};

export { getDepositRoute, getWithdrawalRoute };
